html,
body,
.wrapper {
  background-color: #fff;
}

#sidebar,
#sidebar::after {
  opacity: 1;
}

.content-page {
  max-width: 1600px;
  margin: 20px auto;
}

a {
  cursor: pointer;
}

.icon-title {
  font-size: 12px;
}

.container-fluid {
  max-width: 1600px;
}

.center {
  margin-left: auto !important;
  margin-right: auto !important;
  clear: both !important;
}

.label-title {
  text-align: right;
  font-style: italic;
  margin-bottom: 15px;
}

label {
  font-weight: normal;
  margin-bottom: -10px;
  margin-left: 10px;
  padding: 0 10px;
  background: #fff;
  font-size: 12px;
}

.padding-cell {
  display: block;
  padding: 16px;
}

.box-border {
  border: 1px solid #ccc;
  background-color: #eee;
  padding: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.navbar_blue,
.sidebar .logo {
  background: #4285f4;
}

.logo_header {
  padding-left: 50px !important;
}

.navbar .navbar-nav > li > a {
  color: #fff !important;
}

a {
  color: #4285f4;
}

.breadcrumb {
  cursor: pointer;
}

.card-prodct {
  padding: 5%;
}

.icon-big {
  color: #4285f4;
  font-size: 130px;
}
.icon-middle {
  color: #4285f4;
  font-size: 80px;
}

.icon-small {
  color: #4285f4;
  font-size: 24px;
}

.link-icon {
  display: block;
}

.link-icon span {
  display: inline-block;
  margin-top: -10px;
  line-height: 27px;
  margin-left: 9px;
}

.block-content {
  padding: 10px 40px;
}
.block-content a {
  font-size: 14px;
}

.link-icon i {
  color: #4285f4;
  font-size: 24px;
  display: block;
  margin-top: -9px;
  float: left;
  font-weight: bold;
}

.dropdown i {
  font-size: 21px;
}

.dropdown-menu .divider {
  margin: 2px 0;
}

h1 {
  color: #4285f4;
  font-weight: normal;
  font-size: 28px;
}
h2 {
  color: #4285f4;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 10px !important;
}

.text-gray {
  color: #555;
  font-size: 12px;
}

.card-product a {
  color: #4285f4;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 0.5rem 0;
  display: block;
}

.card .header {
  padding-bottom: 15px;
  background: #f5f5f5;
}

.title-big {
  color: #4285f4;
  font-weight: normal;
  font-size: 32px;
  margin-bottom: 30px;
}
@media (min-width: 991px) {
  .title-big {
    margin-top: 120px;
  }
}

.title-center {
  display: block;
  text-align: center;
}

p {
  margin-bottom: 15px;
}

.search-input {
  padding: 5px;
  width: 100%;
}

.select-action {
  width: 240px;
  margin-right: 20px;
}

.btn-info.btn-fill,
.btn-info.btn-fill:hover,
.btn-info.btn-fill:focus,
.btn-info.btn-fill:active,
.btn-info.btn-fill.active,
.open > .btn-info.btn-fill.dropdown-toggle {
  background-color: #1a73e8;
  border-color: #1a73e8;
  font-size: 16px;
  padding: 5px 20px;
}

.btn-orange {
  background-color: #ec7211 !important;
  border-color: #ec7211 !important;
  padding: 5px 20px;
  font-weight: bold;
}

.btn-full {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}

.table-striped {
  border: 1px solid #999;
}

.btn-box {
  width: 25px !important;
  height: 25px !important;
  padding: 0;
}

.btn-link {
  border-color: #fff;
  background-color: #fff;
  color: #4285f4;
  font-weight: bold;
}

.bg-gray {
  background-color: #eee;
}

.subitem_table td {
  padding: 9px 8px !important;
  font-size: 11px !important;
}

.font-icon {
  font-size: 43px;
}

select {
  padding: 2px;
}

.bar_bottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 80px;
  border-top: 1px solid #aaa;
  padding: 20px;
  background: #fff;
}

.bar_bottom .btn {
  margin-left: 10px;
}

.thumb_ebanx {
  height: 10px;
  width: auto;
  margin-right: 10px;
  margin-bottom: 15px;
}

.spotlight {
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 15px;
}

.subtitle {
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
  background: rgb(154, 187, 209);
  color: #4285f4;
}
.subtitle_container {
  border: 2px solid rgb(154, 187, 209);
  margin: 20px 0;
}

.space {
  height: 200px;
}

.bold {
  font-weight: bold !important;
}

.color-red {
  color: red;
}
.color-blue {
  color: #4285f4;
}
.color-green {
  color: #005a00;
}
.color-orange {
  color: orange;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.float-right {
  float: right;
}

.label-green {
  background: #005a00;
  color: #fff;
  font-weight: bold;
  padding: 8px 5px;
  margin: 5px;
  border-radius: 5px;
}

.full-width {
  width: 100%;
}

.margin-left {
  margin-left: 10px;
}

.margin-top {
  margin-top: 15px;
}

.login-background {
  background-image: url(../img/background.jpg);
  background-position: center top;
  background-size: 100% auto;
  height: 100%;
  padding-top: 10%;
}

.content-login {
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
  margin-top: 11%;
}
.content-login .logo {
  width: 118px;
  margin: 0 auto;
  display: block;
  margin-bottom: 20px;
}

.content-login h1 {
  color: #202124;
  font-weight: normal;
  font-size: 24px;
  text-align: center;
  margin: 10px 0 !important;
}

.content-login p {
  font-size: 16px;
  margin-bottom: 20px;
}

.register-box,
.login-box {
  padding: 40px;
  border: 1px solid #dadce0;
  border-radius: 10px;
  background-color: #fff;
}

.register-box {
  max-width: 1000px;
}

.login-logo {
  display: block;
  margin: 0 auto 20px;
}

.msgLogin {
  margin-top: 40px;
}
/**/

.nps-item {
  cursor: pointer;
  margin-right: 10px;
}

.nps-item.inactive {
  filter: brightness(1.5);
}

.embedH5p {
  border: none;
  width: 100%;
  height: 100%;
}

.sidebar .sidebar-wrapper {
  background: #4285f4;
}

#pluzbot_chat {
  display: none;
}

#pluzbot {
  /*position: fixed;
    z-index: 9999999;
    right: 1%;
    bottom: 7%;*/
}

#pluzbot.chat-enable {
  width: 99%;
  max-width: 100%;
}

#pluzbot.chat-enable #pluzbot_chat {
  display: block !important;
}

/*
#pluzbot_chat:hover,
#pluzbot:hover #pluzbot_chat{
    display: block!important;
}
*/

#pluzbot_open {
  width: 60px;
  height: auto;
  float: right;
  cursor: pointer;
}

#save_success {
  display: none;
}

.react-datetime-picker__inputGroup__input {
  height: auto !important;
}

.sortable ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.box {
  padding: 5px;
  margin: 10px 0;
  border: 1px dotted #ccc;
}

.sortable ul li,
body > li {
  list-style: none;
  margin: 8px;
  padding: 10px;
  border: 1px solid #ccc;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
}

body > li {
  background: #eee;
}

th i {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: -5px;
  margin-right: 5px;
  cursor: pointer;
}

/* Pagination */
.btn_page {
  cursor: pointer;
  display: inline-block;
  border: 1px solid #ccc;
  padding: 2px 6px;
  margin: 2px;
}

.btn_page_active {
  font-weight: bold;
  background: #ccc;
}

/* CHAT */
.chat_loading {
  border: 1px solid #999;
  background: #dbe7ec;
  border-radius: 15px;
  padding: 10px 8px;
  margin: 10px;
  margin-left: 42px;
  width: 50px;
  text-align: center;
}

.chat {
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid #4285f4;
  -webkit-box-shadow: 0 8px 14px rgba(0, 0, 0, 0.3),
    0 0 0 1px rgba(63, 63, 68, 0.1);
  box-shadow: 0 8px 14px rgba(0, 0, 0, 0.3), 0 0 0 1px rgba(63, 63, 68, 0.1);
}

.chat_header {
  color: #4285f4;
  background-color: #fff;
  border: 1px solid #79d3f0;
  font-size: 20px;
  border-bottom: 2px solid #79d3f0;
  padding: 5px 18px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.chat_label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  background-color: #5cb85c;
  float: right;
}

.chat_body {
  /*height: 48vh;*/
  height: 80%;
  overflow-y: scroll;
}
@media (max-height: 615px) {
  .chat_body {
    height: 77%;
  }
}
@media (max-height: 550px) {
  .chat_body {
    height: 72%;
  }
}
@media (max-height: 499px) {
  .chat_body {
    height: 68%;
  }
}
@media (max-height: 420px) {
  .chat_body {
    height: 56%;
  }
}

.chat_message .btn-info {
  color: #fff;
  background: #4285f4;
  border-color: #4285f4;
  margin-top: 20px;
  margin-right: 5px;
  padding: 2px 5px;
}

.chat_message {
  padding: 10px;
  border: 1px solid #f1f1f1;
  margin-bottom: -1px;
}

.chat_message b {
  display: block;
  margin-left: 42px;
}

.chat_message p {
  display: block;
  margin-left: 42px;
  margin-bottom: 0;
  margin-top: 5px;
  color: #8a8686;
}

.chat_message a {
  font-weight: bold;
  color: #4285f4 !important;
}

.chat_answered_question {
  margin: 3px;
  background: #edf1f3;
  padding: 5px;
  border-left: 4px solid #4285f4;
  margin-bottom: 5px;
  border-radius: 2%;
}
.chat_response {
  background: #b5cad3;
}
.chat_response .chat_answer p {
  color: #000;
  font-weight: bold;
}
.chat_answer ul li {
  margin: 10px 0;
}

.chat_answer a {
  cursor: pointer;
}

.chat_answer a:hover {
  text-decoration: underline;
}

.chat_message_admin {
  background: #dbe7ec;
}

.chat_message_admin .chat_answer b {
  color: #4285f4;
}
.chat_message_admin p {
  color: #444;
}

.chat_answered_question p,
.chat_answered_question b {
  margin-left: 10px !important;
  margin-top: 0;
  font-style: italic;
  font-size: 13px;
}

.chat_thumb {
  float: left;
}

.chat_thumb,
.chat_thumb img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.chat_footer {
  position: relative;
  /*
    background-color: #fafafa;
    border-top: 1px solid #eee;
    */
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.chat_footer input {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  padding: 15px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  border: none;
  border-top: 1px solid #79d3f0;
  /*
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    */
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}

.chatSendMessage {
  cursor: pointer;
  z-index: 99999;
  position: absolute;
  right: 15px;
  top: 15px;
  color: #4285f4;
}

.react-confirm-alert-overlay {
  opacity: 1 !important;
}

/* Calendar */
.calendar {
  margin-left: 10px;
  padding-top: 30px;
}
.calendar_header {
  font-weight: bold;
}
.day_week_in_calendar {
  float: left;
  width: 14%;
  text-align: center;
  border: 1px solid #ddd;
  overflow-x: hidden;
}
.item_calendar {
  width: 500px;
  text-align: left;
}
.item_calendar a {
  display: block;
  background: #eee;
  border: 1px solid #ccc;
  margin: 3px 0;
  -webkit-border-radius: 50px;
  -moz-border-radius: 5px;
}

.day_week_in_calendar > div {
  padding: 13px 1px;
  height: 160px;
}
.day_week_in_calendar .current {
  background: #f0f0ff;
}
.day_week_in_calendar .current .number_day {
  font-weight: bold;
}

.calendar_header .day_week_in_calendar {
  height: auto;
}
.number_day {
  border-bottom: 1px dotted #ddd;
  font-size: 12px;
}
.clear {
  clear: both;
  display: block !important;
}

.main-panel {
  width: 100%;
  padding-left: 260px;
  float: left;
}

.hide {
  display: none !important;
}

.color1 {
  color: #4285f4 !important;
  font-weight: bold !important;
}
.color2 {
  color: #5bc0de !important;
  font-weight: bold !important;
}

.edit_question_title {
  width: 80%;
}

.filter_table strong,
.filter_table input {
  margin-left: 20px !important;
}

@media (max-width: 991px) {
  .main-panel {
    padding-left: 0;
  }
}

.item_filter_checkbox {
  display: inline-block;
  margin-left: 6px;
  margin-right: 6px;
}

.table-striped td {
  vertical-align: top !important;
}

.form-control-double {
  height: 60px;
}

.table-billing th,
.table-billing td {
  padding: 5px 10px;
}

.title_table {
  background-color: #4285f4;
  color: #fff;
  font-size: 18px;
}
.title_table th,
.title_table td {
  padding: 10px;
}
